import {
  useEffect,
  useState
} from 'react'
// import { MdAddToPhotos } from 'react-icons/md';
import {
  GraphCardWrapper,
  // GraphCardGroup,
  SkeletonWrapper,
  SpinnerLoader
} from 'components'
import useProject from 'hooks/useProject'

import './index.scss'
import { DragDropComponent } from 'features/projects/project-widget/widgetDragFunctionality';
import { axios } from 'utils';
import { v4 as uuid } from 'uuid'
import { useQuery } from 'react-query';
import { WidgetData } from 'features/projects/project-widget/widgetStatev2';
import { useTour } from '@reactour/tour';
import { Accordion, LoadingOverlay } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { TutorialContainer } from 'features/projects/project-tutorial';
import { Bounce, toast } from 'react-toastify';
import { getPreset } from 'components/top-bar/widgetPreset';
// import { BiExport } from 'react-icons/bi';
import { tourConfig } from './tour';
import { WidgetAddBar } from './WidgetAddBar';
import { SummaryBar } from './SummaryBar';
import CloudVueReportImage from '../../assets/icons/websiteIcons/CloudVue-logo-landscape-v1.png';
import { getDescription } from './data';

function Home() {
  const { setSteps } = useTour()

  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(uuid())
  const { initialLoading } = useProject()
  const [reload, setReload] = useState(1);
  const [labels, setLabels] = useState<[]>([])
  const [widgetObject, setWidgetObject] = useState<WidgetData>(new WidgetData())
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [widgetsLoaded, setWidgetsLoaded] = useState<boolean>(false)
  const [widgetsNeedUpdating, setWidgetsNeedUpdating] = useState<boolean>(false)
  const [fieldsProcessed, setFieldsProcessed] = useState<boolean>(false)
  const [fields, setFields] = useState<string[]>([])
  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }

  const AddLabeledDataChart = (tag: string) => {
    widgetObject.createWidget(tag)
    setReload(reload + 1)
  }
  const updateCallback = () => {
    setWidgetsNeedUpdating(true)
    setQueryKey(uuid())
  }
  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabels(res.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!fieldsProcessed) {
          await axios
            .get('/fields')
            .then((res) => {
              let arr: string[] = res.data
              arr = arr.map((str) => {
                const splittesString = str.split('_')
                let res = ''
                splittesString.forEach((part) => {
                  if (res === '') {
                    res = part.charAt(0).toUpperCase() + part.slice(1)
                  } else {
                    res = `${res} ${part}`
                  }
                })
                return res
              })
              setFields(arr)
              setFieldsProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No resources available.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!widgetsLoaded && !widgetObject.initiallyLoaded) {
          await axios
            .get('/store/widgets')
            .then((res) => {
              setWidgetsLoaded(true)
              const temp = new WidgetData()
              temp.setCallback(() => { updateCallback() })
              temp.setWidgets(JSON.parse(res.data.storeValue).widgets)
              temp.setInitiallyLoaded()
              setWidgetObject(temp)
            })
            .catch(async () => {
              await axios
                .post('/store/widgets', { widgets: widgetObject.widgets })
                .then(() => {
                  setWidgetsLoaded(true)
                })
                .catch((error) => {
                  if (error.response.data.data) {
                    toast.error('Widgets are not set up yet.', {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: 'colored',
                      transition: Bounce,
                    })
                  }
                })
            })
        }
        if (widgetsNeedUpdating) {
          await axios
            .put('/store/widgets', { widgets: widgetObject.widgets })
            .then(() => {
              setWidgetsNeedUpdating(false)
              widgetObject.resourceUpdateFinished()
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Cannot update the widgets.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  const [opened, setOpened] = useState<string>('')

  useEffect(() => {
    if (setSteps) {
      setSteps(
        tourConfig(
          [changeTutorialUpdater, () => { setOpened('AddWidgets') }, () => { setOpened('Summary') }, () => { setOpened('') }]
        )
      )
    }
  }, [widgetObject])

  window.addEventListener('storage', () => {
    // console.log('called')
    if (sessionStorage.getItem('personaChanged') === 'true') {
      // console.log('called')
      sessionStorage.removeItem('personaChanged');
      const temp = new WidgetData()
      temp.setCallback(() => { updateCallback() })
      temp.setWidgets(
        getPreset(
          sessionStorage.getItem('persona')!
        ) as any
      )
      temp.setInitiallyLoaded()
      setWidgetsNeedUpdating(true)
      setWidgetObject(temp)
    }
  })
  useEffect(() => {
    if (sessionStorage.getItem('personaChanged') === 'true') {
      sessionStorage.removeItem('personaChanged');
      const temp = new WidgetData()
      temp.setCallback(() => { updateCallback() })
      temp.setWidgets(
        getPreset(
          sessionStorage.getItem('persona')!
        ) as any
      )
      temp.setInitiallyLoaded()
      setWidgetsNeedUpdating(true)
      setWidgetObject(temp)
    }
  }, [sessionStorage.getItem('personaChanged')])

  window.addEventListener('mouseup', () => {
    if (widgetObject.widgetChanged) {
      widgetObject.setWidgetChanged(false)
      setWidgetsNeedUpdating(true)
      setQueryKey(uuid())
    }
  })

  const groceries = [
    {
      emoji: '🍎',
      value: 'Summary',
      description: <SummaryBar data-tour="theSummaryBar" />,
    },
    {
      emoji: '🍎',
      value: 'AddWidgets',
      description: <div className="test"><WidgetAddBar addCallback={AddLabeledDataChart} data-tour="addWidgetButtonPopUp" /></div>,
    }
  ];
  const items = groceries.map((item) => (
    <Accordion.Item className="border-b-0" key={item.value} value={item.value}>
      <Accordion.Panel className="w-full">{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  type widgetToReportType = {
    idOrDataUrl: string,
    title: string,
    node?: HTMLElement | null
    tag: string,
    summary: string
    w: number,
    h: number,
    comment?: string
  }
  // eslint-disable-next-line new-cap
  const [doc, setDoc] = useState(new jsPDF());
  const [exporting, setExporting] = useState<string>('');
  const [startExportReport, setStartExportReport] = useState<boolean>(false);
  const [startExportPresentation, setStartExportPresentation] = useState<boolean>(false);
  const [imgList, setImgList] = useState<widgetToReportType[]>([]);
  async function dashboardToReport() {
    const ids: widgetToReportType[] = widgetObject.widgets.map((widget) => {
      return ({
        idOrDataUrl: widget.state.id,
        title: widget.tag,
        node: window.document.getElementById(widget.state.id),
        tag: widget.tag,
        summary: widgetObject.getSummary(widget.state.id),
        w: widget.state.w,
        h: widget.state.h,
        comment: widget.state.comment
      })
    })
    if (ids.length !== 0) {
      const newImgList: widgetToReportType[] = []
      await ids.forEach(async (id, idx) => {
        const { node } = id
        if (node !== null && node) {
          await htmlToImage.toPng(node)
            .then((dataUrl) => {
              return dataUrl
            }).then((res) => {
              newImgList.push({
                idOrDataUrl: res,
                title: id.title,
                tag: id.tag,
                summary: id.summary,
                w: id.w,
                h: id.h,
                comment: id.comment
              })
              if (idx === ids.length - 1) {
                setImgList(newImgList)
              }
            })
        }
      })
    } else {
      setExporting('')
      setStartExportReport(false)
      setStartExportPresentation(false)
    }
  }

  const {
    ref,
    width,
  } = useElementSize();

  function getProportion(h: number, reportHeight: number) {
    const y = (h * 30)
    return y / reportHeight
  }

  useEffect(() => {
    if (imgList.length !== 0) {
      doc.addImage(
        CloudVueReportImage,
        'PNG',
        10,
        60,
        200,
        100
      );
      imgList.forEach((img) => {
        if (exporting === 'Report') {
          doc.addPage('a4', 'p');
          doc.addImage(CloudVueReportImage, 'PNG', 2, 2, 30, 15);
          doc.line(0, 17, 300, 17);
          doc.setFontSize(16);
          doc.text(img.title, 10, 27);
          doc.setFontSize(8);
          doc.text(getDescription(img.tag), 10, 32, { maxWidth: 180 })
          const w = (width * img.w) / (12 * getProportion(img.h, 100))
          doc.addImage(
            img.idOrDataUrl,
            'PNG',
            10,
            40,
            w > 190 ? 190 : w,
            w > 190 ? 85 : 100
          );
          doc.text(img.summary, 10, 165, { maxWidth: 180 })
          doc.text('Comments and notes:', 10, 170);
          doc.line(10, 171, 40, 171);
          if (img.comment) doc.text(img.comment, 10, 178, { maxWidth: 180 });
        } else {
          doc.addPage('a4', 'l');
          doc.addImage(CloudVueReportImage, 'PNG', 2, 2, 30, 15);
          doc.setDrawColor(209, 213, 219);
          doc.setLineWidth(0.1)
          doc.line(0, 17, 300, 17);
          doc.setFontSize(24);
          doc.text(img.title, 10, 27);
          doc.setFontSize(12);
          doc.text(getDescription(img.tag), 10, 34, { maxWidth: 270 })
          const w = (width * img.w) / (12 * getProportion(img.h, 140))
          doc.addImage(
            img.idOrDataUrl,
            'PNG',
            10,
            50,
            w > 270 ? 270 : w,
            140
          );
          doc.text(img.summary, 10, 200, { maxWidth: 270 })
        }
      })
      doc.save(`CloudVue_${exporting}_${new Date().toISOString().split('T')[0]}.pdf`)
      setExporting('')
      setStartExportReport(false)
      setStartExportPresentation(false)
      setImgList([])
    }
  }, [imgList])

  useEffect(() => {
    if (startExportReport) setExporting('Report')
    if (startExportPresentation) setExporting('Presentation')
  }, [startExportReport, startExportPresentation])

  useEffect(() => {
    if (exporting === 'Report') {
      // eslint-disable-next-line new-cap
      setDoc(new jsPDF())
      dashboardToReport()
    }
    if (exporting === 'Presentation') {
      // eslint-disable-next-line new-cap
      setDoc(new jsPDF({ orientation: 'l' }));
      dashboardToReport()
    }
  }, [exporting])

  if (error) return <div>An error has occurred</div>
  return (
    <div data-tour="theHomePage" ref={ref}>
      <TutorialContainer
        tag="Homepage"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            [changeTutorialUpdater, () => { setOpened('AddWidgets') }, () => { setOpened('Summary') }, () => { setOpened('') }]
          )
        }
      >
        <div>
          <Accordion color="#5b7b4e" className="bg-[#5b7b4e] shadow-inner border border-[#5b7b4e] h-full p-0 m-0 justify-center" value={opened} transitionDuration={250}>
            {items}
          </Accordion>
          <GraphCardWrapper noPadding spacing={0}>
            <SkeletonWrapper isLoading={initialLoading} height={20} width={200}>
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-xl font-medium">Dashboard</span>
                  {/* <button
                    className="bg-[#eff3ef] hover:bg-[#eff3ef] text-sm text-gray-400
                     hover:text-gray-400 font-semibold px-0 text-wrap underline ml-2"
                    type="button"
                    onClick={() => {
                      setStartExportReport(true)
                    }}
                  >
                    <span className="flex">Export report <BiExport className="ml-1"
                     size={20} /></span>
                  </button>
                  <button
                    className="bg-[#eff3ef] hover:bg-[#eff3ef] text-sm text-gray-400
                     hover:text-gray-400 font-semibold px-0 text-wrap underline ml-2"
                    type="button"
                    onClick={() => {
                      setStartExportPresentation(true)
                    }}
                  >
                    <span className="flex">Export presentation <BiExport
                     className="ml-1" size={20} /></span>
                  </button> */}
                </div>
                <div className="flex justify-end mr-3">
                  <button
                    data-tour="addWidgetButton"
                    className={`bg-${opened === 'Summary' ? '[#668957]' : '[#5b7b4e]'} text-white p-2 rounded-b-lg justify-end ml-1 mr-1`}
                    type="button"
                    onClick={() => {
                      if (opened === '' || opened === 'Summary') {
                        setOpened('AddWidgets')
                      } else {
                        setOpened('')
                      }
                    }}
                  >
                    Add widgets
                  </button>
                  <button
                    data-tour="showSummaryBarButton"
                    className={`bg-${opened === 'AddWidgets' ? '[#668957]' : '[#5b7b4e]'} text-white p-2 rounded-b-lg justify-end ml-1`}
                    type="button"
                    onClick={() => {
                      if (opened === '' || opened === 'AddWidgets') {
                        setOpened('Summary')
                      } else {
                        setOpened('')
                      }
                    }}
                  >
                    Show summary
                  </button>
                </div>
              </div>
            </SkeletonWrapper>
            <div className="xs:p-5 tablet:p-5">
              <LoadingOverlay visible={startExportReport || startExportPresentation} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              <SpinnerLoader isLoading={!dataProcessed
                || !fieldsProcessed
                || !widgetsLoaded}
              >
                <div>
                  {(dataProcessed && fieldsProcessed && widgetsLoaded) && (
                    <DragDropComponent
                      labels={labels}
                      fields={fields}
                      widgetObject={widgetObject}
                    />
                  )}
                </div>
              </SpinnerLoader>
            </div>
          </GraphCardWrapper>
        </div>
      </TutorialContainer>
    </div>
  )
}

export default Home
